import type { CaptureContext, ScopeContext } from '@sentry/types';
import {
  User,
  addBreadcrumb,
  captureEvent,
  captureException,
  captureMessage,
  setContext
} from '@sentry/browser';

export function getEventContext(
  methodName: ScopeContext['level'],
  user?: User
): CaptureContext {
  return {
    user,
    level: methodName,
    // extra: Extras;
    // contexts: Contexts;
    tags: {
      logger: 'loglevel-sentry'
    }
    // fingerprint: string[];
    // requestSession: RequestSession;
  };
}

export {
  captureEvent,
  captureMessage,
  addBreadcrumb,
  setContext,
  captureException
};
